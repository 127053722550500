class ZIDXAccountDashboard implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountDashboardContainer";
    }
    getMatchURL(){
        return "/account/dashboard";
    }
    render(){
        // console.log("dashboard");
    }
}